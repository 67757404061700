import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import Navbar from './components/navbar';
import Footer from './components/footer';
import { Spinner } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import logo from './images/logo.png';
import logo2 from './images/on@3x.png';
import TermsAndConditions from './components/terms';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './styles.css';

function App() {
	function getCurrentTimestamp() {
		const now = new Date();
		const day = String(now.getDate()).padStart(2, '0');
		const month = String(now.getMonth() + 1).padStart(2, '0');
		const year = now.getFullYear();
		return `${day}/${month}/${year}`;
	}

	function CotizarForm() {
		const [formData, setFormData] = useState({
			nombrecompleto: '',
			rut: '',
			email: '',
			telefono: '',
			renta: '',
			utm_source: '',
			utm_medium: '',
			utm_campaign: '',
		});

		const [alert, setAlert] = useState({
			show: false,
			variant: '',
			message: '',
		});

		const [loading, setLoading] = useState(false);

		const handleChange = (e) => {
			const { name, value } = e.target;
			setFormData({
				...formData,
				[name]: value,
			});
		};

		const handleSubmit = (e) => {
			e.preventDefault();
			setLoading(true);
			fetch(
				'https://script.google.com/macros/s/AKfycbwssL5MI46WeZLoi1c9fNo1s6RfyKxpyTbDQyFEa15_Sgu6Ea99t-2anyMH_gIfsh5Q/exec',
				{
					method: 'POST',
					body: new FormData(e.target),
				}
			)
				.then((response) => {
					if (response.ok) {
						setAlert({
							show: true,
							variant: 'success',
							message: '¡Datos enviados con éxito!',
						});
					} else {
						throw new Error('Error interno en los datos');
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						variant: 'danger',
						message: error.message,
					});
				})
				.finally(() => {
					setLoading(false);
				});
		};

		useEffect(() => {
			const urlParams = new URLSearchParams(window.location.search);
			const utmSource = urlParams.get('utm_source');
			const utmMedio = urlParams.get('utm_medium');
			const utmCampaign = urlParams.get('utm_campaign');
			if (utmSource) {
				setFormData((prevData) => ({
					...prevData,
					utm_source: utmSource,
				}));
			}
			if (utmMedio) {
				setFormData((prevData) => ({
					...prevData,
					utm_medium: utmMedio,
				}));
			}
			if (utmCampaign) {
				setFormData((prevData) => ({
					...prevData,
					utm_campaign: utmCampaign,
				}));
			}
		}, []);

		return (
			<>
				<div
					className='d-flex flex-row align-items-center justify-content-around w-auto h-auto'
					id='gradiente'
				>
					<div
						id='contenedor-imagenes'
						className='d-flex flex-column align-items-center justify-content-center gap-2 m-5'
					>
						<Image src={logo} alt='logo' width={300} className='mt-5' />
						<p className='text-white text-center fs-1 fw-bolder fst-italic'>
							ACTIVA TU <br />{' '}
							<span id='texto-bold' className='text-white'>
								MODO
								<br />
								BLACK
							</span>
						</p>
						<Image src={logo2} alt='logo' width={200} className='' />
						<p className='text-white text-center fs-5 fst-italic mb-5'>
							¡Con la llegada del Black Inmobiliario <br />{' '}
							<span className='fw-bolder'>
								activa la emoción de comprar un nuevo hogar!
							</span>{' '}
						</p>
					</div>
					<div
						className='d-flex flex-column align-items-center justify-content-center w-auto p-sm-5 mt-5'
						id='contenedor-formulario'
					>
						{alert.show && (
							<Alert variant={alert.variant}>{alert.message}</Alert>
						)}
						<Form
							method='post'
							action='https://script.google.com/macros/s/AKfycbwssL5MI46WeZLoi1c9fNo1s6RfyKxpyTbDQyFEa15_Sgu6Ea99t-2anyMH_gIfsh5Q/exec'
							id='contact'
							className='d-flex flex-column align-items-center justify-content-center w-auto bg-dark p-4 my-5 rounded-5 shadow text-white'
							onSubmit={handleSubmit}
							style={{ maxWidth: '75%' }}
						>
							<h3 className='text-center mb-5'>¡INSCRÍBETE!</h3>
							<p className='text-center fs-6 fst-italic mb-5'>
								Completa el formulario para recibir{' '}
								<span className='fw-bolder'>
									más información de nuestros proyectos y un asesoramiento
									personalizado
								</span>
							</p>
							<Form.Group className='d-flex flex-column flex-lg-row row row-4 align-content-center justify-content-center mb-3 p-0 w-75'>
								<Form.Control
									type='text'
									name='nombrecompleto'
									placeholder='Nombre completo'
									required
									className='mb-3 p-2 rounded-3 shadow-sm'
									onChange={handleChange}
									value={formData.nombrecompleto}
								/>
								<Form.Control
									type='text'
									name='rut'
									placeholder='RUT'
									required
									className='mb-3 p-2 rounded-3 shadow-sm'
									onChange={handleChange}
									value={formData.rut}
								/>
								<Form.Control
									type='email'
									name='email'
									placeholder='Email'
									required
									className='mb-3 p-2 rounded-3 shadow-sm'
									onChange={handleChange}
									value={formData.email}
								/>
								<Form.Control
									type='tel'
									name='telefono'
									placeholder='Teléfono'
									required
									className='mb-3 p-2 rounded-3 shadow-sm'
									onChange={handleChange}
									value={formData.telefono}
								/>
								<Form.Select
									name='renta'
									placeholder='Renta'
									required
									className='mb-3 p-2 rounded-3 shadow-sm'
									onChange={handleChange}
									value={formData.renta}
								>
									<option value='$ 1.000.000 - $ 1.500.000'>
										$ 1.000.000 - $ 1.500.000
									</option>
									<option value='$ 1.500.001 - $ 2.000.000'>
										$ 1.500.001 - $ 2.000.000
									</option>
									<option value='$ 2.000.001 - $ 2.500.000'>
										$ 2.000.001 - $ 2.500.000
									</option>
									<option value='$ 2.500.001 - $ 3.000.000'>
										$ 2.500.001 - $ 3.000.000
									</option>
									<option value='$ 3.000.001 - $ 3.500.000'>
										$ 3.000.001 - $ 3.500.000
									</option>
									<option value='$ 3.500.001 - más'>$ 3.500.001 - más</option>
								</Form.Select>
								<Form.Select
									name='interes'
									className='mb-3 p-2 rounded-3 shadow-sm'
									onChange={handleChange}
									value={formData.interes}
									required
									placeholder='Elige tu casas/deptos'
								>
									<option value='' disabled selected>Elige tu casas/deptos</option>
									<option value='Elige casas'>Para Casas</option>
									<option value='Elige departamentos'>
										Para Departamentos
									</option>
								</Form.Select>
							</Form.Group>
							<Form.Group>
								<Form.Control
									type='hidden'
									name='timestamp'
									value={getCurrentTimestamp()}
								/>
								<Form.Control
									type='hidden'
									name='utm_source'
									value={formData.utm_source}
								/>
								<Form.Control
									type='hidden'
									name='utm_medium'
									value={formData.utm_medium}
								/>
								<Form.Control
									type='hidden'
									name='utm_campaign'
									value={formData.utm_campaign}
								/>
							</Form.Group>
							<Button
								type='submit'
								variant='warning'
								className='mb-2 mb-lg-0 p-2 rounded-3 shadow-sm w-auto text-uppercase fs-4 fw-bolder'
							>
								{loading ? <Spinner animation='border' size='md' /> : 'Cotiza aquí'}
							</Button>
						</Form>
					</div>
				</div>
			</>
		);
	}
	return (
		<HashRouter>
			<Navbar />
			<Routes>
				<Route path='/' element={<CotizarForm />} />
				<Route path='/terms' element={<TermsAndConditions />} />
			</Routes>
			<Footer />
		</HashRouter>
	);
}

export default App;

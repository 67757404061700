import { Button } from "react-bootstrap";

function TermsAndConditions () {
    return (
        <div className="container my-5 p-5 bg-light rounded-3 shadow-lg" id="terms">
            <h3 className="text-center mb-4">Términos y Condiciones</h3>
            <p>
                La oferta presentada en esta página web estará disponible por un período limitado de días, especificado en la promoción correspondiente. 
                Todas las ofertas están sujetas a la disponibilidad del proyecto en cuestión y pueden ser modificadas o canceladas a discreción del vendedor, 
                en este caso, Inmobiliaria Manquehue.
            </p>
            <p>
                Inmobiliaria Manquehue se reserva el derecho de realizar cambios en los términos y condiciones de la oferta sin previo aviso. 
                La disponibilidad de las propiedades y los descuentos ofrecidos están sujetos a cambios según la demanda y otros factores comerciales.
            </p>
            <p>
                Para obtener más información sobre la disponibilidad de las propiedades y los términos específicos de la oferta, 
                por favor contacte a un representante de ventas de Inmobiliaria Manquehue.
            </p>
            <Button variant="warning" className="mt-3" href="/">
                Volver
            </Button>
        </div>
    )
}

export default TermsAndConditions;
import logo from '../images/inmobil-manquehue--600.png';
import { Navbar, Container, Nav } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import black from '../images/black inmobiliario@3x.png';

function navbar() {
	return (
		<div>
			<Navbar bg='dark' variant='dark' expand='lg' className='py-2'>
				<Container fluid className='px-4 text-white fs-5 text-font-weight-bold'>
					<Navbar.Brand
						href='https://imanquehue.com/'
						className='text-white rounded-1 px-3 d-flex align-items-center gap-2 sm-d-none md-d-none lg-d-none none-d-block'
						target='_blank'
					>
						<Image
							src={logo}
							alt='logo'
							width={100}
							height={100}
							className='rounded-circle'
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls='responsive-navbar-nav' />
					<Navbar.Collapse id='responsive-navbar-nav'>
						<Nav className='me-auto'>
							<Nav.Link
								href='https://cyber.cl/blackinmobiliario/informacion'
								target='_blank'
							>
								Sobre el Black Inmobiliario
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
				<Navbar.Brand className='d-none d-sm-block'>
					<Image src={black} alt='logo' width={100} />
				</Navbar.Brand>
			</Navbar>
		</div>
	);
}

export default navbar;

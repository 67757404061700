import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import logo from '../images/black inmobiliario2@3x.png';
import { Link } from 'react-router-dom';

function Footer() {
	return (
		<footer className='bg-dark text-white py-4'>
			<Container>
				<Row className='align-items-center'>
					<Col md={4} className='text-center mb-3 mb-md-0'>
						<img
							src={logo}
							alt='Logo'
							width={100}
							height={100}
							className='rounded-circle'
						/>
					</Col>
					<Col md={4} className='text-center mb-3 mb-md-0'>
						<div>
							<a
								href='https://www.facebook.com/InmobiliariaManquehue/'
								className='text-white mx-2'
								target='_blank'
								rel='noopener noreferrer'
							>
								<FaFacebook size={30} />
							</a>
							<a
								href='https://cl.linkedin.com/company/inmobiliaria-manquehue'
								className='text-white mx-2'
								target='_blank'
								rel='noopener noreferrer'
							>
								<FaLinkedin size={30} />
							</a>
							<a
								href='https://www.instagram.com/inmobiliariamanquehue/'
								className='text-white mx-2'
								target='_blank'
								rel='noopener noreferrer'
							>
								<FaInstagram size={30} />
							</a>
						</div>
					</Col>
					<Col md={4} className='text-center'>
						<p className='mb-0'>
							<Link to='/terms' className='text-white text-decoration-none'>
								Términos y Condiciones
							</Link>
						</p>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}

export default Footer;
